 <template>
    <modal
        height="auto"
        :show="true"
        scrollable
        size="sm"
        @hide="hide"
    >
        <div slot="header">
            <h2 class="font-normal">References</h2>
        </div>

        <div slot="body" class="references-modal__body">
            <form @submit.prevent="">
                <div class="flex flex__justify-between mb-5">
                    <modal-field class="w-full mr-3" label="Template">
                        <input
                            v-model="template"
                            class="w-full"
                            type="text"
                        />
                    </modal-field>

                    <modal-field class="w-full ml-3" label="Senders ref.">
                        <input
                            v-model="order_no"
                            class="w-full"
                            type="text"
                        />
                    </modal-field>
                </div>

                <div class="flex flex__justify-between mb-5">
                    <modal-field class="w-full mr-3" label="Recipients ref.">
                        <input
                            v-model="consignee_reference"
                            class="w-full"
                            type="text"
                        />
                    </modal-field>

                    <modal-field class="w-full ml-3" label="Booking no">
                        <input
                            v-model="booking_number"
                            class="w-full"
                            type="text"
                        />
                    </modal-field>
                </div>

                <div v-if="adminLevel" class="flex flex__justify-between mb-5">
                    <modal-field class="w-full mr-3" label="Agreement">
                        <input
                            v-model="consignor_agreement_name"
                            class="w-full"
                            type="text"
                        />
                    </modal-field>

                    <modal-field class="w-full ml-3" label="User name">
                        <input
                            v-model="user_name"
                            class="w-full"
                            type="text"
                        />
                    </modal-field>
                </div>

                <div v-if="isShippingOcean">
                    <div class="f-s-16">
                        Ocean tracking credentials
                    </div>

                    <div class="flex mb-2">
                        <modal-field class="w-full shipping" label="Shipping line">
                            <multiselect
                                v-model="shipping_line"
                                :options="shipping_line_options"
                                placeholder="Please select"
                                :disabled="Boolean(containers.filter(container => container).length)"
                                :class="{'is-invalid': submitter && $v.shipping_line.$invalid}"
                                label="name"
                            >
                                <template slot="clear">
                                    <div
                                        v-if="shipping_line"
                                        @mousedown.prevent="shipping_line = null"
                                        class="w-4 absolute top-0 bottom-0 right-0 z-10 flex items-center mr-10 cursor-pointer"
                                    >
                                        <svg-importer icon-name="icons/close" />
                                    </div>
                                </template>
                            </multiselect>
                        </modal-field>
                    </div>
                    <div class="w-1/2 mb-2">
                        <modal-field class="mr-3" label="Bill of lading">
                            <the-mask
                                mask="XXXXXXXXXXXXXXXX"
                                v-model="invoice_no"
                                class="w-full"
                                type="text"
                                :disabled="Boolean(containers.filter(container => container).length)"
                                :class="{'is-invalid': submitter && $v.invoice_no.$invalid}"
                            />
                        </modal-field>
                    </div>

                    <div class="f-s-14 mb-2">
                        or
                    </div>

                    <containers :containers="containers" :disabled="isInvoiceNoOrShippingLineFilled" @input="updateContainers" />
                </div>
            </form>
        </div>
        
        <div slot="footer" class="w__100-p">
            <div class="flex justify-end w-full">
                <div class="flex">
                    <button class="px-10 btn-primary" @click="save">Save</button>
                    
                    <button class="px-8 ml-4 btn-grey-outline" @click="hide">Cancel</button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'
import axios from 'axios';

import Containers from '@/components/ReferenceModalContainers';

const searatesTypeDictionary = {
    invoice: 'BL',
    container: 'CN',
};

const getContainersFromSource = (searatesData) => {
    return searatesData
        ?.map(searate => searate.type === 'CN' ? searate.number : null)
        .filter(searate => searate) || [];
}

const getInvoiceNo = (searates, type) => {
    if (type !== 'BL' || !searates) {
        return '';
    }
    
    return searates[0]?.number;
};

export default {
    name: 'PriceTermsModal',

    components: {
        Containers,
    },

    props: {
        value: {
            type: Object,
            default: () => { return {} },
        },
        searateType: {
            type: String,
        },
        activeSearates: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            order_no: this.value.order_no,
            consignee_reference: this.value.consignee_reference,
            template: this.value.template,
            booking_number: this.value.booking_number,
            user_name: this.value.user_name,
            consignor_agreement_name: this.value.consignor_agreement_name,
            submitter: false,
            containers: getContainersFromSource(this.activeSearates),
            invoice_no: getInvoiceNo(this.activeSearates, this.searateType),
            shipping_line: null,
            shipping_line_options: [],
        }
    },

    watch: {
        activeSearates: {
            immediate: true,
            handler (value) {
                this.invoice_no = getInvoiceNo(value, this.searateType);
                this.shipping_line = this.getShippingLine(value, this.searateType);
            },
        },
    },

    computed: {
        isShippingOcean() {
            return this.value?.transport_mode === 'OCEAN';
        },

        user() {
            return this.$store.getters['auth/user'];
        },

        adminLevel() {
            return this.user?.level === 'super';
        },

        isContainersLength() {
            return Boolean(this.containers?.filter(container => container).length);
        },

        isInvoiceNoOrShippingLineFilled() {
            return Boolean(this.invoice_no?.length || this.shipping_line?.length);
        },
    },

    created() {
        this.init();
    },

    methods: {
        async init() {
            const { data: { data } } = await axios.get(this.$apiUrl.sealines.base);
            this.shipping_line_options = data;

            this.shipping_line = this.getShippingLine(this.activeSearates, this.searateType)
        },
        getShippingLine(searates, type) {
            if (type !== 'BL' || !searates) {
                return '';
            }

            const sealine = searates[0]?.sealine;

            if (!sealine) {
                return;
            }

            return this.shipping_line_options.find(option => option.code === sealine);
        },
        hide() {
            this.containers = [''];
            this.$emit('hide');
        },

        async updateContainers(value) {
            this.containers = value;
            await this.$forceUpdate();
        },
        
        async save() {
            this.submitter = true;

            if (this.$v.$invalid) {
                return;
            }

            const result = {
                order_no: this.order_no,
                consignee_reference: this.consignee_reference,
                template: this.template,
                booking_number: this.booking_number,
            }

            if (this.adminLevel) {
                result.user_name = this.user_name;
                result.consignor_agreement_name = this.consignor_agreement_name;
            }

            if (this.isShippingOcean) {
                const unbindedSearateIds = await this.unbindFromSearates();
    
                const searateToBind = await this.getSearatesToBind(unbindedSearateIds);
                await this.bindToSearates(searateToBind);
            }

            this.$emit('save', result);

            this.hide()
        },

        getFormSearateType() {
            const invoiceOrShippingLine = this.invoice_no || this.shipping_line?.code;
            const containersLength = this.containers.filter(container => container).length;

            if (invoiceOrShippingLine) {
                return 'BL';
            }

            if (containersLength) {
                return 'CN';
            }
        },
        
        async getSearatesToBind(excludeSearateIds) {
            const searateType = this.getFormSearateType();

            if (searateType === 'BL') {

                const data = {
                    consignment_uuid: this.$route.params.uuid,
                    type: searatesTypeDictionary.invoice,
                    sealine: this.shipping_line?.code,
                    number: this.invoice_no,
                };

                const hasInvoiceNoAndShippingLineFilled = Boolean(this.invoice_no.length && this.shipping_line.code.length);
                const invoiceNoOrShippingLineDiffers = 
                    this.invoice_no !== getInvoiceNo(this.activeSearates, searateType)
                    || this.shipping_line !== this.getShippingLine(this.activeSearates, searateType);

                return hasInvoiceNoAndShippingLineFilled && invoiceNoOrShippingLineDiffers ? data : null;
            }

            if (searateType === 'CN') {
                const containersFromSource = getContainersFromSource(this.activeSearates)
                    .filter(containerFromSource => !excludeSearateIds?.includes(containerFromSource));
                const formContainers = this.containers.filter(container => container);
                const containersToBind = formContainers.filter(container => !containersFromSource.includes(container));

                return containersToBind.length
                    ? containersToBind.map(container => ({
                        consignment_uuid: this.$route.params.uuid,
                        type: searatesTypeDictionary.container,
                        sealine: 'auto',
                        number: container,
                    }))
                    : null;
            }

            return null;
        },
        async bindToSearates(data) {
            if (!data) {
                return;
            }

            try {
                if (Array.isArray(data)) {
                    const promises = data.map(containerData => axios.post(this.$apiUrl.consignments.searates, containerData));
                    await await Promise.all(promises);
    
                    this.$snotify.success('Ocean tracking info will be updated in 10 minutes');

                    return;
                }
    
                await axios.post(this.$apiUrl.consignments.searates, data);

                this.$snotify.success('Ocean tracking info will be updated in 10 minutes');
            } catch (error) {
                this.$snotify.error('Failed to bind shipment to searates');
            }
        },
        async unbindFromSearates() {
            const activeSearates = this.value.searates.filter(searate => searate.active);
            const searatesToUnbind = activeSearates.filter(searate => {
                if (this.searateType === searatesTypeDictionary.container) {
                    return !this.containers.includes(searate.number);
                }

                if (this.searateType === searatesTypeDictionary.invoice) {
                    return this.invoice_no !== activeSearates[0]?.number || this.shipping_line !== activeSearates[0]?.sealine;
                }
                
                return false;
            }).map(searate => searate.id);

            if (!searatesToUnbind.length) {
                return;
            }

            try {
                const promises = searatesToUnbind.map(searateId => axios.delete(`${this.$apiUrl.consignments.searates}/${searateId}`));
                await Promise.all(promises);
                return searatesToUnbind;
            } catch (error) {
                this.$snotify.error('Failed to unbind shipment from searates');
            }
        },
    },

    validations: {
        invoice_no: {
            required: requiredIf(function() {
                return this.shipping_line;
            }),
        },
        shipping_line: {
            required: requiredIf(function() {
                return this.invoice_no.length;
            }),
        },
    }
}
</script>
<style lang="scss">
.references-modal {
    &__body {
        .multiselect__content-wrapper {
            max-height: 160px !important;
        }
    }
    .multiselect--disabled {
        user-select: none;
        
        .multiselect__tags {
            background: rgba(0,0,0,.025);
        }
    }
}
.searates-container-field {
    &__button {
        display: block;
        width: 100%;
        height: 33px;
        border: 1px solid rgba(230, 230, 230, 1);
    }
    
    &__wrapper {
        position: relative;

        input {
            padding-right: 25px;
        }
    }

    &__remove {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
    }
}
</style>