<template>
    <div class="flex flex-wrap">
        <div
            v-for="(container, index) in value"
            :key="`container_${index}`"
            class="w-1/2 mb-3 searates-container-field__wrapper"
        >
            <modal-field
                :class="{ 'mr-3': index % 2 === 0, 'ml-3': index % 2 === 1 }"
                label="Container No"
            >
                <the-mask
                    mask="XXXXXXXXXXXXXXXX"
                    :value="value[index]"
                    class="w-full"
                    type="text"
                    :disabled="disabled"
                    @input="updateContainerValue($event, index)"
                />
                <button type="button" v-if="value[index]" class="searates-container-field__remove" @click="removeContainer(index)">
                    <svg-importer icon-name="icons/close" />
                </button>
            </modal-field>
        </div>
        <div class="w-1/2 mb-3 searates-container-field__wrapper">
            <div :class="{ 'mr-3': value.length % 2 === 0, 'ml-3': value.length % 2 === 1 }">
                <modal-field class="cursor-pointer" label="Add container">
                    <button
                        type="button"
                        class="searates-container-field__button"
                        
                        :disabled="disabled"
                        @click="addContainer"
                    >
                        <fa :icon="['fal', 'plus']" />
                    </button>
                </modal-field>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        containers: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            value: this.containers,
        }
    },
    computed: {
        isContainersLength() {
            return Boolean(this.value?.filter(container => container).length);
        },
    },
    methods: {
        updateContainerValue(value, index) {
            this.value[index] = value;

            this.emitInput();
        },
        addContainer() {
            this.value.push('');

            this.emitInput();
        },
        removeContainer(index) {
            this.value.splice(index, 1);

            this.emitInput();
        },
        emitInput() {
            this.$emit('input', this.value);
        },
        forceRenderUpdate() {
            this.$forceUpdate();
        },
    },
}
</script>